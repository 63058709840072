import React from "react"
import { Col } from "react-bootstrap"

/**
 * Item for the features list.
 */
const FeaturesItem = ({ icon = "paper-plane", title, children }) => (
  <Col xs={12} md={6} lg={3} data-aos="fade-up" data-aos-delay="300">
    <div className="mini-box">
      <i className={`fa fa-${icon}`}></i>
      <strong>{title}</strong>
      <span>{children}</span>
    </div>
  </Col>
)

export default FeaturesItem
