import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./footer-image.css"

/**
 * Displays an image that pops up behind the footer.
 */
const FooterImage = () => {
  const data = useStaticQuery(graphql`
    query {
      panoramaCity: file(relativePath: { eq: "stadt-panorama-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="footer-image padding-top-70" data-aos="fade-up">
      <Container>
        <Row>
          <Col
            xs={12}
            className="align-self-center mobile-bottom-fix-big"
            data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
          >
            <Img fluid={data.panoramaCity.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FooterImage
