import React from "react"

import "./features.css"
import { Container, Row, Col } from "react-bootstrap"
import FeaturesItem from "./features-item"

const Features = () => (
  <section className="mini pt-5">
    <div className="mini-content">
      <Container>
        {/* <Row>
          <Col className="offset-lg-3 col-lg-6">
            <div className="info" data-aos="fade-up">
              <h1>Ihre Vorteile</h1>
              <p>
                Überzeugen Sie sich von den Vorteilen, <br />
                die der BürgerBot für Sie und Ihre Bürger*innen bietet.
              </p>
            </div>
          </Col>
        </Row> */}

        <Row>
          <FeaturesItem title="24/7 erreichbar" icon="history">
            Rund um die Uhr für Bürgeranliegen erreichbar
          </FeaturesItem>
          <FeaturesItem title="Fotos und Standorte" icon="picture-o">
            Multimedia-Inhalte direkt über den Messenger
          </FeaturesItem>
          <FeaturesItem title="Keine eigene App" icon="cubes">
            Nutzung durch bereits installierte Messenger
          </FeaturesItem>
          <FeaturesItem title="Künstliche Intelligenz" icon="code-fork">
            Weiterentwicklung durch Machine Learning
          </FeaturesItem>
          <FeaturesItem title="Smart City" icon="globe">
            Durch Digitalisierung heute die Stadt von morgen sein
          </FeaturesItem>
          <FeaturesItem title="Ticketsystem" icon="laptop">
            Direkte Antwortmöglichkeit und automatisierte Filterung
          </FeaturesItem>
          <FeaturesItem title="EU-Hosting" icon="server">
            Datenschutzkonform durch ISO 27001-zertifizierte Server
          </FeaturesItem>
          <FeaturesItem title="Bot-Charakter" icon="users">
            Bestimmen Sie selbst das Auftreten Ihres BürgerBots
          </FeaturesItem>
        </Row>
      </Container>
    </div>
  </section>
)

export default Features
