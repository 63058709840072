import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Features from "../../components/features"
import PageHeading from "../../components/page-heading"
import FooterImage from "../../components/footer-image"
import MailToLink from "../../components/mailto-link"

const StaedtePage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            email
          }
        }
      }
      slideBlue: file(relativePath: { eq: "slide-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenTicket: file(relativePath: { eq: "screen-ticket.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mapIntegration: file(relativePath: { eq: "map-integration-shadow.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phoneBot: file(relativePath: { eq: "phone-bot.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Für Städte"
        description="Mit dem BürgerBot wird auch Ihre Stadt zur digitalen Smart City und stärkt die Bürgerbeteiligung."
      />
      <PageHeading background={true}>
        <p>
          Der BürgerBot sorgt für mehr Bürgerbeteiligung und einen direkten
          Draht zu Ihren Bürgerinnen und Bürgern. <br />
          Erfahren Sie hier, wie auch Ihre Stadtverwaltung davon profitieren
          kann.
        </p>
      </PageHeading>

      <section
        className="section padding-top-70 padding-bottom-100"
        data-aos="fade-up"
      >
        <Container>
          <Row>
            <Col
              xs={12}
              className="d-block d-md-none align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.phoneBot.childImageSharp.fluid} />
            </Col>
            <Col xs={12} md={6} className="align-self-center mobile-top-fix">
              <div className="left-heading">
                <h2 className="section-title">Bürgeranliegen erhalten</h2>
              </div>
              <div className="left-text">
                <p>
                  Der BürgerBot ermöglicht es Ihren Bürgerinnen und Bürgern,
                  einfach und schnell mit Ihnen in Kontakt zu treten - via
                  WhatsApp, Facebook oder Telegram. Es muss also keine eigene
                  App installiert werden! Der BürgerBot ist rund um die Uhr
                  erreichbar und kann Anliegen entgegen nehmen.
                </p>
              </div>
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col
              md={6}
              lg={5}
              className="d-none d-md-block align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.phoneBot.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="hr"></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-up">
        <Container>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={5}
              className="align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.screenTicket.childImageSharp.fluid} />
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col xs={12} md={6} className="align-self-center mobile-top-fix">
              <div className="left-heading">
                <h2 className="section-title">Alles in einem System</h2>
              </div>
              <div className="left-text">
                <p>
                  Der BürgerBot filtert mit künstlicher Intelligenz (KI) die
                  Anliegen vor und lernt ständig dazu. Mit der an Ihre
                  Bedürfnisse angepassten Ticketübersicht ist der interne
                  Austausch ganz einfach und ermöglicht effektive Bearbeitungen.
                  Fragen oder Rückmeldungen zu einem Anliegen? Kein Problem.
                  Einfach über die Ticketübersicht antworten und der BürgerBot
                  schickt die Nachricht an den Messenger der Bürgerin oder des
                  Bürgers zurück.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="hr"></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section padding-bottom-80" data-aos="fade-up">
        <Container>
          <Row>
            <Col
              xs={12}
              className="d-block d-md-none align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.mapIntegration.childImageSharp.fluid} />
            </Col>
            <Col xs={12} md={6} className="align-self-center mobile-top-fix">
              <div className="left-heading">
                <h2 className="section-title">Ihr Weg zur Smart City</h2>
              </div>
              <div className="left-text">
                <p>
                  Der BürgerBot ist Ihr intelligenter Partner auf dem Weg zur
                  digitalen und smarten Stadt. Er lässt sich für
                  unterschiedlichste Aufgaben erweitern. So kann der BürgerBot
                  z. B. auch für Meinungsumfragen und als Sprachrohr für die
                  Kommunikation mit dem Bürger genutzt werden. Haben wir Ihr
                  Interesse geweckt? Dann schreiben Sie uns einfach
                  unverbindlich an und wir beantworten Ihre offenen Fragen.
                </p>
              </div>
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col
              md={6}
              lg={5}
              className="d-none d-md-block align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.mapIntegration.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="padding-bottom-80" data-aos="fade-up">
        <Container>
          <Row>
            <Col
              xs={12}
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center mobile-top-fix"
            >
              <MailToLink
                email={data.site.siteMetadata.contact.email}
                className="custom-btn btn mr-5 mb-3"
              >
                Team kontaktieren
              </MailToLink>
              <a
                href="/files/BürgerBot Informationen.pdf"
                target="_blank"
                className="custom-btn btn mb-3"
              >
                Informationen als PDF <small>(4 MB)</small>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <Features />
      <FooterImage />
    </Layout>
  )
}
export default StaedtePage
